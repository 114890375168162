import React, { Component } from "react";
import axios from "../../../http/axios";
import parse from "html-react-parser";
import { Spin } from "antd";

export default class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceList: [],
      totalService: 0,
      colSize: "col-lg-12 col-md-12 col-sm-12",
      showLoadingService: false,
    };
  }

  componentDidMount = () => {
    this.getServiceList();
  };

  getServiceList = async () => {
    this.setState({
      showLoadingService: true,
    });
    try {
      const res = await axios.get("page/service");
      // console.log(res);
      if (res.status === 200) {
        var serviceList = res.data.result;
        var totalSize = serviceList.length;
        var col = "";
        switch (totalSize) {
          case 1:
            col = "col-lg-12 col-md-12 col-sm-12";
            break;
          case 2:
            col = "col-lg-6 col-md-6 col-sm-6";
            break;
          case 3:
            col = "col-lg-4 col-md-4 col-sm-6";
            break;
          default:
            col = "col-lg-3 col-md-4 col-sm-6";
            break;
        }

        this.setState({
          serviceList,
          colSize: col,
          totalService: serviceList.length,
        });
      } else {
        this.setState({
          serviceList: [],
          totalService: 0,
          colSize: "col-lg-12 col-md-12 col-sm-12",
        });
      }
      this.setState({
        showLoadingService: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        serviceList: [],
        showLoadingService: false,
        totalService: 0,
        colSize: "col-lg-12 col-md-12 col-sm-12",
      });
    }
  };

  render() {
    const { serviceList } = this.state;
    return (
      <>
        <section className="services spad" style={{ paddingTop: "40px" }}>
          <div className="container">
            <Spin spinning={this.state.showLoadingService}>
              <div className="row">
                {serviceList.length
                  ? serviceList.map((item, idx) => (
                      <div className={this.state.colSize} key={idx}>
                        <div
                          className="text-center"
                          style={{
                            paddingLeft: "0 !important",
                            position: "relative",
                            marginBottom: "20px",
                          }}
                        >
                          <img
                            src={item.link_icon}
                            alt=""
                            className="img-social"
                            style={{
                              borderRadius: "60%",
                              height: "70px",
                              width: "80px",
                              marginBottom: "40px",
                            }}
                          />
                          <h6 className="mb-3">{item.title}</h6>
                          <div>{parse(item.content)}</div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </Spin>
          </div>
        </section>
      </>
    );
  }
}
