/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import NavMenu from "./NavMenu";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../http/axios";
// import { Divider } from "antd";
import "./HeaderCustom.css";
import NavMenu from "./NavMenu";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // totalCart: this.props.totalCart,
      categoryList: this.props.categoryList,
      positionHeader: "relative",
      width: "150",
      height: "50",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.categoryList.length !== prevProps.categoryList.length) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.setState({
        categoryList: this.props.categoryList,
      });
    }
  }

  componentDidMount = () => {
    // if (localStorage.getItem('totalCart')) {
    //   this.props.handleChangeTotalCart(localStorage.getItem('totalCart'))
    // }
    this.getdatacart();
    // this.getCategoryList();

    const menu = document.querySelector(".menu");
    if (
      Math.min(window.screen.width, window.screen.height) < 768 ||
      navigator.userAgent.indexOf("Mobi") > -1
    ) {
      this.setState({
        width: "80",
        height: "30",
      });
    } else {
      this.setState({
        width: "150",
        height: "50",
      });
    }

    // var mybutton = document.getElementsByClassName("header-custom");

    // window.onscroll = function() {scrollFunction()};

    window.addEventListener("scroll", (e) => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        // alert('test')
        this.setState({
          positionHeader: "fixed",
        });
      } else {
        this.setState({
          positionHeader: "relative",
        });
      }
    });
    window.addEventListener("resize", (e) => {
      var isMobile =
        Math.min(window.screen.width, window.screen.height) < 768 ||
        navigator.userAgent.indexOf("Mobi") > -1;
      // console.log(window.screen.width);
      if (isMobile) {
        this.setState({
          width: "80",
          height: "30",
        });
      } else {
        this.setState({
          width: "150",
          height: "50",
        });
      }
    });

    // function scrollFunction() {
    //   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    //     alert('test')
    //     this.setState({
    //       positionHeader: 'fixed'
    //     })
    //   } else {
    //     this.setState({
    //       positionHeader: 'relative'
    //     })
    //   }
    // }
    // const SearchSwitch = document.querySelector(".search-switch-custom");
    // const SearchModal = document.querySelector(".search-model");
    const menuSection = menu.querySelector(".menu-section-custom");
    const menuArrow = menu.querySelector(".menu-mobile-arrow");
    const menuClosed = menu.querySelector(".menu-mobile-close");
    const menuToggle = document.querySelector(".menu-mobile-toggle");
    const menuOverlay = document.querySelector(".overlay");
    let subMenu;

    menuSection.addEventListener("click", (e) => {
      if (!menu.classList.contains("active")) {
        return;
      }
      if (e.target.closest(".menu-item-has-children")) {
        const hasChildren = e.target.closest(".menu-item-has-children");
        showSubMenu(hasChildren);
      }
    });

    menuArrow.addEventListener("click", () => {
      hideSubMenu();
    });
    // SearchSwitch.addEventListener("click", () => {
    //   SearchModal.fadeIn(400);
    // });

    menuToggle.addEventListener("click", () => {
      toggleMenu();
    });

    menuClosed.addEventListener("click", () => {
      toggleMenu();
    });

    menuOverlay.addEventListener("click", () => {
      toggleMenu();
    });

    // Show & Hide Toggle Menu Function
    function toggleMenu() {
      // alert('tesss')
      menu.classList.toggle("active");
      menuOverlay.classList.toggle("active");
    }

    // Show the Mobile Side Menu Function
    function showSubMenu(hasChildren) {
      subMenu = hasChildren.querySelector(".menu-subs");
      subMenu.classList.add("active");
      subMenu.style.animation = "slideLeft 0.5s ease forwards";
      const menuTitle =
        hasChildren.querySelector("i").parentNode.childNodes[0].textContent;
      menu.querySelector(".menu-mobile-title").innerHTML = menuTitle;
      menu.querySelector(".menu-mobile-header").classList.add("active");
    }

    // Hide the Mobile Side Menu Function
    function hideSubMenu() {
      subMenu.style.animation = "slideRight 0.5s ease forwards";
      setTimeout(() => {
        subMenu.classList.remove("active");
      }, 300);

      menu.querySelector(".menu-mobile-title").innerHTML = "";
      menu.querySelector(".menu-mobile-header").classList.remove("active");
    }

    // Windows Screen Resizes Function
    window.onresize = function () {
      if (this.innerWidth > 991) {
        if (menu.classList.contains("active")) {
          toggleMenu();
        }
      }
    };
  };

  getCategoryList = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get("categories");
      // console.log(res);
      if (res.status === 200) {
        var categoryList = res.data.result;

        this.setState({
          categoryList,
        });
      } else {
        this.setState({
          categoryList: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        categoryList: [],
        showLoading: false,
      });
    }
  };

  getdatacart = async () => {
    // this.setState({
    //   showLoading: true,
    // });
    try {
      const res = await axios.get(`cart`);
      // console.log(res.related);
      if (res.status === 200) {
        var totalCart = 0;
        res.data.result.map(
          (item) => (totalCart = totalCart + parseInt(item.qty))
        );

        this.props.handleChangeTotalCart(totalCart);
      } else {
        this.setState({
          listCart: [],
        });
        this.props.handleChangeTotalCart(0);
      }

      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      // console.log(err);
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
      this.props.handleChangeTotalCart(0);
    }
  };

  handleLogout = () => {
    localStorage.clear();
    window.location.href = "/auth/login";
  };

  render() {
    // console.log(this.props);
    return (
      <header
        className="header-custom"
        style={{
          position: this.state.positionHeader,
        }}
      >
        <div className="container-header-custom">
          <section className="wrapper-header-custom">
            <div className="header-item-left">
              <a href="/" className="brand">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/logosidebar.png"}
                  alt=""
                  width={this.state.width}
                  height={this.state.height}
                  // width={150}
                  // height={50}
                />
              </a>
            </div>
            <div className="header-item-center">
              <div className="overlay"></div>
              <nav className="menu" id="menu">
                <div className="menu-mobile-header">
                  <button type="button" className="menu-mobile-arrow">
                    <i className="fa fa-angle-left"></i>
                  </button>
                  <div className="menu-mobile-title"></div>
                  <button type="button" className="menu-mobile-close">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <NavMenu {...this.state} object={this.props.object} />
              </nav>
            </div>

            <div className="header-item-right">
              {localStorage.getItem("userInfo") ? null : (
                <a
                  href="/auth/login"
                  className="menu-icon"
                  style={{
                    marginRight: "10px",
                    fontSize: "14px",
                    textDecoration: "none",
                  }}
                >
                  Login
                </a>
              )}
              {localStorage.getItem("userInfo") ? (
                <a
                  href="#"
                  className="menu-icon"
                  style={{ marginRight: "10px", textDecoration: "none" }}
                >
                  <Dropdown drop="start">
                    <Dropdown.Toggle as={"span"}>
                      <span className="icon_profile"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="/page/order-list">
                        <span style={{ fontSize: "12px" }}>Order List</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/page/detail-profile">
                        <span style={{ fontSize: "12px" }}>Detail Profile</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/page/change-password">
                        <span style={{ fontSize: "12px" }}>
                          Change Password
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => this.handleLogout()}>
                        <span style={{ fontSize: "12px" }}>Logout</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </a>
              ) : null}
              <span
                className="icon_search search-switch"
                style={{ cursor: "pointer" }}
              ></span>

              <a
                href="/page/shopping-cart"
                className="menu-icon"
                style={{
                  fontSize: "18px",
                  color: "#111111",
                  position: "relative",
                  marginRight: "10px",
                  textDecoration: "none",
                }}
              >
                <span className="icon_bag_alt"></span>
                <div
                  className="tip"
                  style={{
                    position: "absolute",
                    right: "-12px",
                    top: "-11px",
                    height: "18px",
                    width: "18px",
                    background: "#111111",
                    fontSize: "10px",
                    fontWeight: "500",
                    color: "#ffffff",
                    lineHeight: "18px",
                    textAlign: "center",
                    borderRadius: "50%",
                    textDecoration: "none",
                  }}
                >
                  {this.props.totalCart}
                </div>
              </a>
              <button type="button" className="menu-mobile-toggle">
                {/* <i className="fa fa-bars"></i> */}
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </section>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalCart: state.totalCart,
    listCart: state.listCart,
  };
};
const mapStateToDispatch = (dispatch) => {
  return {
    handleChangeTotalCart: (valueBaru) =>
      dispatch({ type: "UPDATE_TOTAL_CART", newValue: valueBaru }),
    handleAddTotalCart: (valueBaru) =>
      dispatch({ type: "ADD_TOTAL_CART", newValue: valueBaru }),
    handleUpdateListCart: (valueBaru) =>
      dispatch({ type: "UPDATE_LIST_CART", newValue: valueBaru }),
  };
};

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(Header));
