import React, { Component } from "react";
import Kategori from "./Kategori";
import Product from "./Product";
import Banner from "./Banner";
// import Trend from "./Trend";
import Service from "./Service";
import { Helmet } from "react-helmet";

export default class Home extends Component {
  render() {
    return (
      <>
        <div>
          <Helmet>
            <title>Home - Bikers Dream Administrator</title>
            <meta name="description" content="Home page" />
          </Helmet>
          <Kategori />
          <Service />

          <Product />
          <Banner />
          {/* <Trend /> */}
        </div>
      </>
    );
  }
}
