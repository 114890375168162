/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import {
//   Carousel,
//   CarouselControl,
//   CarouselIndicators,
//   CarouselItem,
//   // UncontrolledCarousel,
// } from "reactstrap";
import axios from "../../../http/axios";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPlayer from "react-player";
import Carousel from "nuka-carousel";
import "../../../antdstyle.css";
// const this.state.allBanner = [
//   {
//     src: sliderImage1,
//     id: 1,
//     altText: "Slide 1",
//     caption: "Slide 1",
//     header: "Slide 1 Header"
//   },
//   {
//     src: sliderImage2,
//     id: 2,
//     altText: "Slide 2",
//     caption: "Slide 2",
//     header: "Slide 2 Header"
//   },
//   {
//     src: sliderImage3,
//     id: 3,
//     altText: "Slide 3",
//     caption: "Slide 3",
//     header: "Slide 3 Header"
//   }
// ]

export default class Kategori extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerList: [],
      allBanner: [],
      bannerHighlight: [],
      totalBanner: 0,
      showLoadingCategories: false,
      classCategories: "",
      indexSlide: 0,
      activeIndex: 0,
      intervalBanner: 5000,
      heightDiv: "600px",
      objectFitImage: "cover",
      styleVideo: {},
      isMobile: false,
    };
  }

  componentDidMount = () => {
    this.getBanner();
    if (
      Math.min(window.screen.width, window.screen.height) < 768 ||
      navigator.userAgent.indexOf("Mobi") > -1
    ) {
      this.setState({
        classCategories: "",
        heightDiv: "220px",
        objectFitImage: "cover",
        styleVideo: {},
        isMobile: true,
      });
    } else {
      this.setState({
        classCategories: "categories__large__item",
        heightDiv: "600px",
        objectFitImage: "cover",
        styleVideo: { marginTop: "-2%" },
        isMobile: false,
      });
    }

    window.addEventListener("resize", (e) => {
      var isMobile =
        Math.min(window.screen.width, window.screen.height) < 768 ||
        navigator.userAgent.indexOf("Mobi") > -1;
      // console.log(window.screen.width);
      if (isMobile) {
        this.setState({
          classCategories: "",
          heightDiv: "220px",
          objectFitImage: "cover",
          styleVideo: {},
          isMobile: true,
        });
      } else {
        this.setState({
          classCategories: "categories__large__item",
          heightDiv: "600px",
          objectFitImage: "cover",
          styleVideo: { marginTop: "-2%" },
          isMobile: false,
        });
      }
    });
  };

  getBanner = async () => {
    this.setState({
      showLoadingCategories: true,
    });
    try {
      const res = await axios.get("page/slide");
      // console.log(res);
      if (res.status === 200) {
        var totalBanner = res.data.result.length;
        var allBanner = res.data.result;
        if (allBanner.length) {
          var intervalBanner = allBanner[0].flag === "1" ? 5000 : 12000;
          this.setState({
            intervalBanner,
            indexSlide: 0,
          });
        }
        this.setState({
          totalBanner,
          allBanner,
        });
      } else {
        this.setState({
          bannerList: [],
          bannerHighlight: [],
          allBanner: [],
          totalBanner: 0,
        });
      }
      this.setState({
        showLoadingCategories: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        bannerList: [],
        bannerHighlight: [],
        allBanner: [],
        showLoadingCategories: false,
        totalBanner: 0,
      });
    }
  };

  gotoLink = (dt) => {
    window.open(dt.url, "_blank");
  };

  onExiting = () => {
    // var intervalBanner = item.flag === "1" ? 5000 : 12000;
    // console.log(intervalBanner);
    // this.setState({
    //   intervalBanner,
    // });
    this.animating = true;
  };
  playCarousel = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };
  pauseCarousel = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.allBanner.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.allBanner.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  handleChangeInterval = (flag) => {
    console.log(flag);
    var intervalBanner = flag === "1" ? 5000 : 12000;
    console.log(intervalBanner);
    this.setState({
      intervalBanner,
    });
  };

  // renderSlide = (allBanner) => {

  // };
  handleNext = (indexSlide) => {
    // console.log("after", indexSlide);
    var allBanner = this.state.allBanner.length;
    var lastIndex = parseInt(allBanner) - 1;

    if (indexSlide === lastIndex) {
      setTimeout(() => {
        this.setState({ indexSlide: 0 });
      }, 5000);
    } else {
      this.setState({ indexSlide });
    }
  };
  handleBefore = (indexSlide) => {
    // console.log("before", indexSlide);
    // var allBanner = this.state.allBanner.length;
    // var lastIndex = parseInt(allBanner) - 1;
    // if (indexSlide === lastIndex) {
    //   this.setState({ indexSlide: 0 });
    // } else {
    //   this.setState({ indexSlide });
    // }
  };

  render() {
    const {
      // bannerList,
      // bannerHighlight,
      // totalBanner,
      allBanner,
      // activeIndex,
    } = this.state;
    return (
      <div>
        <section className="categories" style={{ marginTop: "2px" }}>
          {/* <section className="categories" style={{marginTop: '60px'}}> */}
          <div className="container-fluid">
            <div className="row">
              {allBanner.length ? (
                <div style={{ height: this.state.heightDiv, padding: 0 }}>
                  {/* <div style={{ padding: 0 }}> */}
                  <Carousel
                    style={{ height: this.state.heightDiv }}
                    // adaptiveHeight={true}
                    autoplayInterval={5000}
                    autoplay={true}
                    wrapAround={true}
                    // autoplayReverse={true}
                    pauseOnHover={true}
                    // afterSlide={(index, obj) => console.log(index, obj)}
                    // slideIndex={this.state.indexSlide}
                    // slideIndex={this.state.indexSlide}
                    // beforeSlide={(indexSlide) => this.handleBefore(indexSlide)}
                    // afterSlide={(indexSlide) => this.handleNext(indexSlide)}
                    // autoplayReverse={true}
                    renderCenterLeftControls={({ previousSlide }) => (
                      <i
                        className="fa fa-angle-left"
                        onClick={previousSlide}
                        style={{
                          fontSize: "40px",
                          fontWeight: "bold",
                          marginLeft: "20px",
                        }}
                      ></i>
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                      <i
                        className="fa fa-angle-right"
                        onClick={nextSlide}
                        style={{
                          fontSize: "40px",
                          fontWeight: "bold",
                          marginRight: "20px",
                        }}
                      ></i>
                    )}
                    renderBottomCenterControls={({ currentSlide }) => (
                      <div></div>
                    )}
                  >
                    {/* {this.renderSlide(allBanner)} */}
                    {allBanner.map((item) =>
                      item.flag === "1" ? (
                        <img
                          // height={600}
                          src={item.link_image}
                          // className="img-fluid"
                          alt={item.id}
                          style={{
                            objectFit: this.state.objectFitImage,
                            width: "100%",
                            height: this.state.heightDiv,
                          }}
                        />
                      ) : (
                        <div className="player-wrapper">
                          <ReactPlayer
                            className={
                              this.state.isMobile ? "" : "react-player"
                            }
                            url={item.url_video}
                            playing={true}
                            loop={true}
                            width={"100%"}
                            playsinline={true}
                            // height="600px"
                            style={this.state.styleVideo}
                            height={"100%"}
                            muted
                            volume={1}
                            controls={false}
                            // wrapper={
                            //   <div
                            //     style={{
                            //       display: "flex",
                            //       alignItems: "center",
                            //       justifyContent: "center",
                            //       objectFit: "cover",
                            //     }}
                            //   ></div>
                            // }
                            // progressInterval={1000}
                          />
                        </div>
                      )
                    )}
                  </Carousel>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
