/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Kategori from "./Kategori";
// import Product from "./Product";
// import Banner from "./Banner";
// import Trend from "./Trend";
// import Service from "./Service";
import { Helmet } from "react-helmet";
import axios from "../../../http/axios";
import BreadCumb from "../../component/BreadCumb";
import { formatDateEng } from "../../../helper/helper";
import { Pagination, Spin, Tag } from "antd";
import "antd/dist/antd.min.css";
import "../../../antdstyle.css";

export default class FindArtikel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArtikel: [],
      ContentObj: [],
      dataArtikelTerbaru: [],
      showLoading: false,
      showLoadingList: false,
      content: "",
      title: "",
      image: "",
      searchValue: "",
      pagefound: true,
      key: "",

      pageNumber: 1,
      totalSize: 0,
      pageSize: 9,
    };
  }

  componentDidMount = () => {
    // console.log(this.props);
    if (this.props.match.params.key) {
      this.getContentByKey(
        this.state.pageNumber,
        this.state.pageSize,
        this.props.match.params.key
      );

      this.setState({
        key: this.props.match.params.key,
      });
    }
    // else {
    //   this.getContent(this.state.pageNumber, this.state.pageSize);
    // }
    this.getContentList();
  };

  getContentByKey = async (pageNumber, pageSize, key = null) => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get(
        `page/artikel/desc/updated_at/${pageSize}?page=${pageNumber}`,
        {
          params: {
            key: key,
          },
        }
      );
      // const res = await axios.get("page/artikel");
      // console.log(res);
      if (res.status === 200) {
        var dataArtikel = res.data.result;

        this.setState({
          dataArtikel,
          totalSize: res.data.total_data,
        });
      } else {
        this.setState({
          dataArtikel: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        dataArtikel: [],
        showLoading: false,
      });
    }
  };

  getContentList = async () => {
    this.setState({
      showLoadingList: true,
    });
    try {
      const res = await axios.get("page/artikel/desc/updated_at/10");
      // console.log(res);
      if (res.status === 200) {
        var dataArtikelTerbaru = res.data.result;

        this.setState({
          dataArtikelTerbaru,
        });
      } else {
        this.setState({
          dataArtikelTerbaru: [],
        });
      }
      this.setState({
        showLoadingList: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        dataArtikelTerbaru: [],
        showLoadingList: false,
      });
    }
  };

  getContent = async () => {
    this.setState({
      showLoading: true,
    });
    var slug = this.props.match.params.slug;
    try {
      const res = await axios.get("page/artikel", {
        params: {
          slug_content: slug,
        },
      });
      // console.log(res);
      if (res.status === 200) {
        var ContentObj = res.data.result;

        if (!Array.isArray(ContentObj)) {
          this.setState({
            content: ContentObj.content,
            title: ContentObj.title,
            image: ContentObj.link_image,
            pagefound: true,
          });
        } else {
          this.setState({
            content: "",
            title: "",
            image: "",
            pagefound: false,
          });
        }
      } else {
        this.setState({
          content: "",
          title: "",
          image: "",
          pagefound: false,
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        content: "",
        title: "",
        image: "",
        showLoading: false,
        pagefound: false,
      });
    }
  };

  handleSearch = async (e) => {
    e.preventDefault();
    localStorage.setItem("searchValueArticel", this.state.searchValue);
    // console.log(this.state.searchValue);
    window.location.href = `/page/articles/search-by-key=${this.state.searchValue}`;
  };

  onChangePageNumber = (pageNumber) => {
    // console.log("onChangePageNumber");
    setTimeout(() => {
      this.setState({
        pageNumber,
      });
      // this.getContent(pageNumber, this.state.pageSize);
      this.getContentByKey(pageNumber, this.state.pageSize, this.state.key);
    }, 200);
  };
  onShowSizeChange = (pageNumber, pageSize) => {
    // console.log("onShowSizeChange");
    this.setState({
      pageNumber,
      pageSize,
    });
    // this.getContent(pageNumber, pageSize);
    this.getContentByKey(pageNumber, pageSize, this.state.key);
  };

  handlePreview = (record) => {
    this.setState({
      id: record.id,
      id_parent: record.id_parent,
      title: record.title,
      image: record.image,
      url: record.url,
      old_image: record.image,
    });
    window.location.href = `/page/content-artikel/${record.slug}`;
  };

  render() {
    const { title, dataArtikel } = this.state;
    var titlePage = title
      ? `${title} - Bikers Dream Administrator`
      : `Content - Bikers Dream Administrator`;
    return (
      <>
        <div>
          <Helmet>
            <title>{titlePage}</title>
            <meta name="description" content="Content Page" />
          </Helmet>

          <BreadCumb title={"Article"} ProductName={title} />

          <section className="blog-details spad">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <Spin spinning={this.state.showLoading}>
                    {this.state.key !== "" ? (
                      <div className="row mb-4">
                        <div className="col-md-12">
                          <span style={{ marginRight: 8 }}>Key:</span>
                          <Tag color="red">{this.state.key}</Tag>
                        </div>
                      </div>
                    ) : null}
                    <div className="row">
                      {dataArtikel.length ? (
                        <>
                          {dataArtikel.map((item, key) => (
                            <div
                              className="col-lg-12 col-md-12 col-sm-12"
                              key={key}
                            >
                              <div
                                className="blog__item"
                                onClick={() => this.handlePreview(item)}
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="blog__item__pic set-bg"
                                  style={{
                                    backgroundImage: `url(${
                                      item.link_image
                                        ? item.link_image
                                        : "https://webservice.bikersdream.id/media/1653965136_07b71b9400ef30a70161.png"
                                    })`,
                                  }}
                                ></div>
                                <div className="blog__item__text">
                                  <h4 style={{ color: "black" }}>
                                    <a
                                      href="#"
                                      style={{ color: "black" }}
                                      onClick={() => this.handlePreview(item)}
                                    >
                                      {item.title.length > 50
                                        ? `${item.title.substr(0, 50)}...`
                                        : item.title}
                                    </a>
                                  </h4>
                                  <ul style={{ padding: 0 }}>
                                    {/* <li>by <span>Ema Timahe</span></li> */}
                                    <li>{formatDateEng(item.updated_at)}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className="col-lg-12 col-md-12 mt-2">
                            <div
                              className="text-center"
                              style={{ marginTop: "50px" }}
                            >
                              <Pagination
                                current={this.state.pageNumber}
                                onChange={this.onChangePageNumber}
                                total={this.state.totalSize}
                                showTotal={(total) => `Total ${total} posts`}
                                pageSize={this.state.pageSize}
                                showSizeChanger
                                onShowSizeChange={this.onShowSizeChange}
                                pageSizeOptions={[9, 20, 50, 100]}
                                // responsive={true}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="blog__details__content">
                          <div className="blog__details__item">
                            <img
                              src={
                                "https://webservice.bikersdream.id/media/1653965149_d1e461d9c42bd06f3152.png"
                              }
                              alt=""
                            />
                            {/* <div className="blog__details__item__title">
                          <h4>Sorry, page not found</h4>
                        </div> */}
                            <div className="blog__details__desc">
                              <h4>Sorry, no article</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Spin>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="blog__sidebar__item">
                    {/* <div className="section-title">
                      <h4>Search</h4>
                    </div> */}
                    <div class="footer__newslatter">
                      <form onSubmit={this.handleSearch}>
                        <input
                          type="text"
                          value={this.state.searchValue}
                          onChange={(e) =>
                            this.setState({
                              searchValue: e.target.value,
                            })
                          }
                          placeholder="Search article"
                        />
                        <button type="submit" class="site-btn">
                          Search
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="blog__sidebar__item">
                    <div className="section-title">
                      <h4>Feature posts</h4>
                    </div>
                    {this.state.dataArtikelTerbaru.length ? (
                      this.state.dataArtikelTerbaru.map((item) => (
                        <a
                          href={`/page/content-artikel/${item.slug}`}
                          className="blog__feature__item"
                        >
                          <div className="blog__feature__item__pic">
                            <img
                              src={item.link_image}
                              alt=""
                              style={{
                                width: "70px",
                                height: "70px",
                              }}
                            />
                          </div>
                          <div className="blog__feature__item__text">
                            <h6>
                              {item.title.length > 50
                                ? `${item.title.substr(0, 50)}...`
                                : item.title}
                            </h6>
                            <span>{formatDateEng(item.updated_at)}</span>
                          </div>
                        </a>
                      ))
                    ) : (
                      <a href="#" className="blog__feature__item">
                        <div className="blog__feature__item__text">
                          <h6>No post</h6>
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
