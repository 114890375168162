import React, { Component } from "react";

export default class SearchModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem("searchValue")) {
      this.setState({
        searchValue: localStorage.getItem("searchValue"),
      });
    }
  };

  handleSearch = async (e) => {
    e.preventDefault();
    localStorage.setItem("searchValue", this.state.searchValue);
    // console.log(this.state.searchValue);
    window.location.href = `/page/product/1/9/search-by-key=${this.state.searchValue}`;
  };

  render() {
    return (
      <>
        <div className="search-model">
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="search-close-switch">+</div>
            <form className="search-model-form" onSubmit={this.handleSearch}>
              <input
                type="text"
                id="search-input"
                placeholder="Search here....."
                value={this.state.searchValue}
                onChange={(e) =>
                  this.setState({
                    searchValue: e.target.value,
                  })
                }
              />
            </form>
          </div>
        </div>
      </>
    );
  }
}
