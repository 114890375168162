/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Spin } from "antd";
import React, { Component } from "react";
// import { currencyFormatIndo } from "../../../helper/helper";
import axios from "../../../http/axios";

export default class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      showLoading: false,
    };
  }

  componentDidMount = () => {
    this.getProductAll();
  };

  getProductAll = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get("product-list/desc/updated_at/9?page=1");
      // console.log(res);
      if (res.status === 200) {
        var productList = res.data.result;

        this.setState({
          productList,
        });
      } else {
        this.setState({
          productList: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        productList: [],
        showLoading: false,
      });
    }
  };
  render() {
    const { productList } = this.state;
    return (
      <>
        <section className="product spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="section-title">
                  <h4>New product</h4>
                </div>
              </div>
              <div className="col-lg-8 col-md-8">
                <ul className="filter__controls">
                  {/* <li className="active" data-filter="*"> */}
                  <li className="active" data-filter="*">
                    <a
                      href="/page/shop-product/1/9"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      See All
                    </a>
                  </li>
                  {/* <li data-filter=".women">Women’s</li>
                  <li data-filter=".men">Men’s</li>
                  <li data-filter=".kid">Kid’s</li>
                  <li data-filter=".accessories">Accessories</li>
                  <li data-filter=".cosmetic">Cosmetics</li> */}
                </ul>
              </div>
            </div>
            <div className="row property__gallery">
              {this.state.showLoading ? (
                // <div className="loader"></div>
                <Spin spinning={this.state.showLoading}></Spin>
              ) : productList.length ? (
                productList.map((item, idx) => (
                  <div className="col-lg-4 col-md-4 col-sm-6" key={idx}>
                    <div className="product__item">
                      <div
                        className="product__item__pic set-bg"
                        style={
                          {
                            // backgroundImage: `url(${
                            //   item.sampul
                            //     ? item.sampul
                            //     : "https://webservice.bikersdream.id/media/1653965136_07b71b9400ef30a70161.png"
                            // })`,
                            // backgroundPosition: "center",
                            // backgroundRepeat: "no-repeat",
                            // objectFit: 'fill !important'
                            // backgroundSize: "500px",
                            // backgroundSize: "270px 360px",
                            // height: "270px",
                          }
                        }
                      >
                        {item.stock === "Habis" ? (
                          <div className="label sale">OUT OF STOCK</div>
                        ) : null}
                        {/* <div className="label sale">OUT OF STOCK</div> */}
                        <img
                          src={
                            item.sampul
                              ? item.sampul
                              : "https://webservice.bikersdream.id/media/1653965136_07b71b9400ef30a70161.png"
                          }
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                        />

                        <ul className="product__hover">
                          <li>
                            <a
                              href={
                                item.sampul
                                  ? item.sampul
                                  : "https://webservice.bikersdream.id/media/1653965136_07b71b9400ef30a70161.png"
                              }
                              className="image-popup"
                              target={"_blank"}
                              style={{ textDecoration: "none" }}
                            >
                              <span className="arrow_expand"></span>
                            </a>
                          </li>
                          {/* <li>
                            <a href="#">
                              <span className="icon_bag_alt"></span>
                            </a>
                          </li> */}
                        </ul>
                      </div>
                      <div className="product__item__text">
                        <h6>
                          <a
                            href={`/page/product-detail/${item.catslug}/${item.slugname}`}
                            style={{
                              textDecoration: "none",
                              fontWeight: "bold",
                            }}
                          >
                            {item.productname.length > 26
                              ? item.productname.substr(0, 24) + "..."
                              : item.productname}
                          </a>
                        </h6>
                        {/* <div className="rating">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div> */}
                        {/* <div className="product__price">Rp {}</div> */}
                        <div
                          className="product__price"
                          style={{ fontWeight: "normal" }}
                        >
                          {item.type_discount !== "0" ? (
                            <>
                              {/* Rp. {item.last_price ? currencyFormatIndo(item.last_price) : 0}{" "} */}
                              {/* <span>{item.price ? currencyFormatIndo(item.price) : 0}</span> */}
                              Rp. {item.last_price ? item.last_price : 0}{" "}
                              <span>{item.price ? item.price : 0}</span>
                            </>
                          ) : (
                            <>Rp. {item.price ? item.price : 0}</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : null}
            </div>
          </div>
        </section>
      </>
    );
  }
}
