/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Kategori from "./Kategori";
// import Product from "./Product";
// import Banner from "./Banner";
// import Trend from "./Trend";
// import Service from "./Service";
import { Helmet } from "react-helmet";
import { formatDateEng } from "../../../helper/helper";
import axios from "../../../http/axios";
import BreadCumb from "../../component/BreadCumb";
import { Pagination, Spin } from "antd";
import "antd/dist/antd.min.css";
import "../../../antdstyle.css";
export default class Artikel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArtikel: [],
      showLoading: false,
      content: "",
      title: "",
      isModalVisible: false,
      id: "",
      id_parent: "",
      image: "",
      old_image: "",
      url: "",

      pageNumber: 1,
      totalSize: 0,
      pageSize: 9,
    };
  }

  componentDidMount = () => {
    // console.log(this.props);
    if (this.props.match.params.key) {
      this.getContentByKey(
        this.state.pageNumber,
        this.state.pageSize,
        this.props.match.params.key
      );
    } else {
      this.getContent(this.state.pageNumber, this.state.pageSize);
    }
  };

  getContentByKey = async (pageNumber, pageSize, key = null) => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get(
        `page/artikel/desc/updated_at/${pageSize}?page=${pageNumber}`,
        {
          params: {
            key: key,
          },
        }
      );
      // const res = await axios.get("page/artikel");
      // console.log(res);
      if (res.status === 200) {
        var dataArtikel = res.data.result;

        this.setState({
          dataArtikel,
          totalSize: res.data.total_data,
        });
      } else {
        this.setState({
          dataArtikel: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        dataArtikel: [],
        showLoading: false,
      });
    }
  };

  getContent = async (pageNumber, pageSize) => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get(
        `page/artikel/desc/updated_at/${pageSize}?page=${pageNumber}`
      );
      // const res = await axios.get("page/artikel");
      // console.log(res);
      if (res.status === 200) {
        var dataArtikel = res.data.result;

        this.setState({
          dataArtikel,
          totalSize: res.data.total_data,
        });
      } else {
        this.setState({
          dataArtikel: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        dataArtikel: [],
        showLoading: false,
      });
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handlePreview = (record) => {
    this.setState({
      id: record.id,
      id_parent: record.id_parent,
      title: record.title,
      image: record.image,
      url: record.url,
      old_image: record.image,
    });
    window.location.href = `/page/content-artikel/${record.slug}`;
  };

  onChangePageNumber = (pageNumber) => {
    // console.log("onChangePageNumber");
    setTimeout(() => {
      this.setState({
        pageNumber,
      });
      this.getContent(pageNumber, this.state.pageSize);
    }, 200);
  };
  onShowSizeChange = (pageNumber, pageSize) => {
    // console.log("onShowSizeChange");
    this.setState({
      pageNumber,
      pageSize,
    });
    this.getContent(pageNumber, pageSize);
  };

  render() {
    const { dataArtikel } = this.state;
    return (
      <>
        <div>
          <Helmet>
            <title>Article - Bikers Dream Administrator</title>
            <meta name="description" content="Article Page" />
          </Helmet>

          <BreadCumb title={"Article"} />

          <section className="blog spad">
            <div className="container">
              <Spin spinning={this.state.showLoading}>
                <div className="row">
                  {dataArtikel.length ? (
                    <>
                      {dataArtikel.map((item, key) => (
                        <div className="col-lg-4 col-md-4 col-sm-6" key={key}>
                          <div
                            className="blog__item"
                            onClick={() => this.handlePreview(item)}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="blog__item__pic set-bg"
                              style={{
                                backgroundImage: `url(${
                                  item.link_image
                                    ? item.link_image
                                    : "https://webservice.bikersdream.id/media/1653965136_07b71b9400ef30a70161.png"
                                })`,
                              }}
                            ></div>
                            <div className="blog__item__text">
                              <h4 style={{ color: "black" }}>
                                <a
                                  href="#"
                                  style={{ color: "black" }}
                                  onClick={() => this.handlePreview(item)}
                                >
                                  {item.title.length > 50
                                    ? `${item.title.substr(0, 50)}...`
                                    : item.title}
                                </a>
                              </h4>
                              <ul style={{ padding: 0 }}>
                                {/* <li>by <span>Ema Timahe</span></li> */}
                                <li>{formatDateEng(item.updated_at)}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="col-lg-12 col-md-12 mt-2">
                        <div
                          className="text-center"
                          style={{ marginTop: "50px" }}
                        >
                          <Pagination
                            current={this.state.pageNumber}
                            onChange={this.onChangePageNumber}
                            total={this.state.totalSize}
                            showTotal={(total) => `Total ${total} posts`}
                            pageSize={this.state.pageSize}
                            showSizeChanger
                            onShowSizeChange={this.onShowSizeChange}
                            pageSizeOptions={[9, 20, 50, 100]}
                            // responsive={true}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="blog__details__content">
                      <div className="blog__details__item">
                        <img
                          src={
                            "https://webservice.bikersdream.id/media/1653965149_d1e461d9c42bd06f3152.png"
                          }
                          alt=""
                        />
                        {/* <div className="blog__details__item__title">
                          <h4>Sorry, page not found</h4>
                        </div> */}
                        <div className="blog__details__desc">
                          <h4>Sorry, no article</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Spin>
            </div>
          </section>
        </div>
      </>
    );
  }
}
