/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Kategori from "./Kategori";
// import Product from "./Product";
// import Banner from "./Banner";
// import Trend from "./Trend";
// import Service from "./Service";
// import { Helmet } from "react-helmet";
import { MetaTags } from "react-meta-tags";
import axios from "../../../http/axios";
import BreadCumb from "../../component/BreadCumb";
import parse from "html-react-parser";
import { formatDateEng } from "../../../helper/helper";
// import { RWebShare } from "react-web-share";
import { Modal, Divider, Space } from "antd";
import "antd/dist/antd.min.css";
import "../../../antdstyle.css";
import {
  // EmailShareButton,
  // FacebookShareButton,
  // HatenaShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  // LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TelegramShareButton,
  // TumblrShareButton,
  // TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  // WhatsappShareButton,
  // WorkplaceShareButton,
  EmailIcon,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LineIcon,
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  LineShareButton,
  EmailShareButton,
} from "react-share";

export default class ContentArtikel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ContentObj: [],
      dataArtikel: [],
      showLoading: false,
      showLoadingList: false,
      content: "",
      title: "",
      image: "",
      searchValue: "",
      sluglabel: "",
      updated_at: "",
      pagefound: true,
      isModalShare: false,
      imgThumbnail: null,
    };
  }

  componentDidMount = () => {
    // console.log(this.props);
    this.getContent();
    this.getContentList();
  };

  getContentList = async () => {
    this.setState({
      showLoadingList: true,
    });
    try {
      const res = await axios.get("page/artikel/desc/updated_at/10");
      // console.log(res);
      if (res.status === 200) {
        var dataArtikel = res.data.result;

        this.setState({
          dataArtikel,
        });
      } else {
        this.setState({
          dataArtikel: [],
        });
      }
      this.setState({
        showLoadingList: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        dataArtikel: [],
        showLoadingList: false,
      });
    }
  };

  getContent = async () => {
    this.setState({
      showLoading: true,
    });
    var slug = this.props.match.params.slug;
    try {
      const res = await axios.get("page/artikel", {
        params: {
          slug_content: slug,
        },
      });
      // console.log(res);
      if (res.status === 200) {
        var ContentObj = res.data.result;

        if (!Array.isArray(ContentObj)) {
          this.setState({
            content: ContentObj.content,
            title: ContentObj.title,
            image: ContentObj.link_image,
            updated_at: ContentObj.updated_at,
            sluglabel: ContentObj.sluglabel,
            pagefound: true,
          });
        } else {
          this.setState({
            content: "",
            title: "",
            image: "",
            sluglabel: "",
            updated_at: "",
            pagefound: false,
          });
        }
      } else {
        this.setState({
          content: "",
          title: "",
          sluglabel: "",
          image: "",
          updated_at: "",
          pagefound: false,
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        content: "",
        title: "",
        image: "",
        sluglabel: "",
        updated_at: "",
        showLoading: false,
        pagefound: false,
      });
    }
  };

  handleSearch = async (e) => {
    e.preventDefault();
    localStorage.setItem("searchValueArticel", this.state.searchValue);
    // console.log(this.state.searchValue);
    window.location.href = `/page/articles/search-by-key=${this.state.searchValue}`;
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  render() {
    const { content, title, image, pagefound, updated_at } = this.state;
    var titlePage = title
      ? `${title} - Bikers Dream Administrator`
      : `Content - Bikers Dream Administrator`;

    console.log(updated_at);
    return (
      <>
        <div>
          {/* <Helmet>
            <title>{titlePage}</title>
            <meta name="description" content="Content Page" />
          </Helmet> */}
          <MetaTags>
            <title>{titlePage}</title>
            <meta name="description" content={title} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={window.location.origin} />
          </MetaTags>

          <BreadCumb title={"Article"} ProductName={title} />

          <section className="blog-details spad">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  {pagefound ? (
                    <div className="blog__details__content">
                      <div className="product__details__button">
                        {/* <RWebShare
                          data={{
                            text: String(title).toUpperCase(),
                            url: window.location.href,
                            title: title,
                          }}
                          onClick={() => console.log("shared successfully!")}
                        > */}
                        <button
                          type="button"
                          className="cart-btn"
                          style={{
                            background: "#fff",
                            border: "1px solid #0273BB",
                            color: "#0273BB",
                          }}
                          onClick={() => this.toggleModal("isModalShare")}
                        >
                          <i className="fa fa-share-alt" /> Share
                        </button>
                        {/* </RWebShare> */}
                      </div>
                      <div
                        className="blog__details__item"
                        // style={{ border: "1px solid #ccc" }}
                      >
                        <img
                          src={
                            image
                              ? image
                              : "https://webservice.bikersdream.id/media/1653965136_07b71b9400ef30a70161.png"
                          }
                          alt=""
                        />
                        <div className="blog__details__item__title">
                          {/* <span className="tip">Street style</span> */}
                          <h4>{title}</h4>
                          {/* <ul>
                            <li>
                              <div className="product__details__button">
                                <RWebShare
                                  data={{
                                    text: title,
                                    url: window.location.href,
                                    title: title,
                                  }}
                                  onClick={() =>
                                    console.log("shared successfully!")
                                  }
                                >
                                  <button
                                    type="button"
                                    className="cart-btn"
                                    style={{
                                      background: "#fff",
                                      border: "1px solid #0273BB",
                                      color: "#0273BB",
                                    }}
                                  >
                                    <i className="fa fa-share-alt" /> Share
                                  </button>
                                </RWebShare>
                              </div>
                            </li>
                          </ul> */}
                          <ul>
                            {/* <li>by <span>Ema Timahe</span></li> */}
                            <li>
                              <i className="fa fa-calendar" />{" "}
                              {updated_at ? formatDateEng(updated_at) : ""}
                            </li>
                            {/* <li>39 Comments</li> */}
                          </ul>
                        </div>
                      </div>
                      <div
                        className="blog__details__desc"
                        style={{ marginTop: "50px" }}
                      >
                        {parse(content)}
                      </div>
                      {/* <div className="product__details__button">
                        <RWebShare
                          data={{
                            text: title,
                            url: window.location.href,
                            title: title,
                          }}
                          onClick={() => console.log("shared successfully!")}
                        >
                          <button
                            type="button"
                            className="cart-btn"
                            style={{
                              background: "#fff",
                              border: "1px solid #0273BB",
                              color: "#0273BB",
                            }}
                          >
                            <i className="fa fa-share-alt" /> Share
                          </button>
                        </RWebShare>
                      </div> */}
                    </div>
                  ) : (
                    <div className="blog__details__content">
                      <div className="blog__details__item">
                        <img
                          src={
                            "https://webservice.bikersdream.id/media/1653965149_d1e461d9c42bd06f3152.png"
                          }
                          alt=""
                        />
                        {/* <div className="blog__details__item__title">
                          <h4>Sorry, page not found</h4>
                        </div> */}
                        <div className="blog__details__desc">
                          <h4>Sorry, page not found</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="blog__sidebar__item">
                    {/* <div className="section-title">
                      <h4>Search</h4>
                    </div> */}
                    <div className="footer__newslatter">
                      <form onSubmit={this.handleSearch}>
                        <input
                          type="text"
                          value={this.state.searchValue}
                          onChange={(e) =>
                            this.setState({
                              searchValue: e.target.value,
                            })
                          }
                          placeholder="Search article"
                        />
                        <button type="submit" className="site-btn">
                          Search
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="blog__sidebar__item">
                    <div className="section-title">
                      <h4>Feature posts</h4>
                    </div>
                    {this.state.dataArtikel.length ? (
                      this.state.dataArtikel.map((item) => (
                        <a
                          href={`/page/content-artikel/${item.slug}`}
                          className="blog__feature__item"
                        >
                          <div className="blog__feature__item__pic">
                            <img
                              src={item.link_image}
                              alt=""
                              style={{
                                width: "70px",
                                height: "70px",
                              }}
                            />
                          </div>
                          <div className="blog__feature__item__text">
                            <h6>
                              {item.title.length > 50
                                ? `${item.title.substr(0, 50)}...`
                                : item.title}
                            </h6>
                            <span>{formatDateEng(item.updated_at)}</span>
                          </div>
                        </a>
                      ))
                    ) : (
                      <a href="#" className="blog__feature__item">
                        <div className="blog__feature__item__text">
                          <h6>No post</h6>
                        </div>
                      </a>
                    )}

                    {/* <a href="#" className="blog__feature__item">
                                <div className="blog__feature__item__pic">
                                    <img src="img/blog/sidebar/fp-2.jpg" alt="">
                                </div>
                                <div className="blog__feature__item__text">
                                    <h6>Amf Cannes Red Carpet Celebrities Kend...</h6>
                                    <span>Seb 17, 2019</span>
                                </div>
                            </a>
                            <a href="#" className="blog__feature__item">
                                <div className="blog__feature__item__pic">
                                    <img src="img/blog/sidebar/fp-3.jpg" alt="">
                                </div>
                                <div className="blog__feature__item__text">
                                    <h6>Amf Cannes Red Carpet Celebrities Kend...</h6>
                                    <span>Seb 17, 2019</span>
                                </div>
                            </a> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Modal
            title={"Share Article"}
            visible={this.state.isModalShare}
            // onOk={false}
            onCancel={() => this.toggleModal("isModalShare")}
            footer={null}
            width={1200}
            // style={{ top: 20 }}
          >
            <div className="row">
              <div className="col-lg-12">
                <h2>{title}</h2>
                <Divider />
                {/* <h3>Mau bagikan lewat mana?</h3> */}
                <div>
                  <Space size={4}>
                    <WhatsappShareButton
                      title={String(title).toUpperCase()}
                      url={window.location.href}
                    >
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <FacebookShareButton
                      quote={String(title).toUpperCase()}
                      url={window.location.href}
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>

                    <TwitterShareButton
                      title={String(title).toUpperCase()}
                      url={window.location.href}
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <TelegramShareButton
                      title={String(title).toUpperCase()}
                      url={window.location.href}
                    >
                      <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                    <LineShareButton
                      title={String(title).toUpperCase()}
                      url={window.location.href}
                    >
                      <LineIcon size={32} round={true} />
                    </LineShareButton>
                    <EmailShareButton
                      body={String(title).toUpperCase()}
                      subject={title}
                      url={window.location.href}
                    >
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                  </Space>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
