/* eslint-disable jsx-a11y/anchor-is-valid */
import { Pagination, Modal, Spin } from "antd";
import React, { Component } from "react";
// import Kategori from "./Kategori";
// import Product from "./Product";
// import Banner from "./Banner";
// import Trend from "./Trend";
// import Service from "./Service";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import axios from "../../../http/axios";
import BreadCumb from "../../component/BreadCumb";
export default class VideoInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataVideos: [],
      showLoading: false,
      content: "",
      title: "",
      isModalVisible: false,
      id: "",
      id_parent: "",
      icon: "",
      old_icon: "",
      url: "",

      pageNumber: 1,
      totalSize: 0,
      pageSize: 9,
    };
  }

  componentDidMount = () => {
    // console.log(this.props);
    this.getContent(this.state.pageNumber, this.state.pageSize);
  };

  getContent = async (pageNumber, pageSize) => {
    this.setState({
      showLoading: true,
    });
    try {
      // const res = await axios.get("page/video");
      const res = await axios.get(
        `page/video/desc/updated_at/${pageSize}?page=${pageNumber}`
      );
      // console.log(res);
      if (res.status === 200) {
        var dataVideos = res.data.result;

        this.setState({
          dataVideos,
          totalSize: res.data.total_data,
        });
      } else {
        this.setState({
          dataVideos: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        dataVideos: [],
        showLoading: false,
      });
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handlePreview = (record) => {
    this.toggleModal("isModalVisible");
    this.setState({
      id: record.id,
      id_parent: record.id_parent,
      title: record.title,
      icon: record.icon,
      url: record.url,
      old_icon: record.icon,
    });
  };

  onChangePageNumber = (pageNumber) => {
    // console.log("onChangePageNumber");
    setTimeout(() => {
      this.setState({
        pageNumber,
      });
      this.getContent(pageNumber, this.state.pageSize);
    }, 200);
  };
  onShowSizeChange = (pageNumber, pageSize) => {
    // console.log("onShowSizeChange");
    this.setState({
      pageNumber,
      pageSize,
    });
    this.getContent(pageNumber, pageSize);
  };

  render() {
    const { dataVideos } = this.state;
    return (
      <>
        <div>
          <Helmet>
            <title>Videos - Bikers Dream Administrator</title>
            <meta name="description" content="Videos Page" />
          </Helmet>

          <BreadCumb title={"Videos"} />

          <section className="blog spad">
            <div className="container">
              <Spin spinning={this.state.showLoading}>
                <div className="row">
                  {dataVideos.length
                    ? dataVideos.map((item, key) => (
                        <div className="col-lg-4 col-md-4 col-sm-6" key={key}>
                          <div
                            className="blog__item"
                            onClick={() => this.handlePreview(item)}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="blog__item__pic set-bg"
                              style={{
                                backgroundImage: `url(${item.link_icon})`,
                              }}
                            ></div>
                            <div
                              className="blog__item__text"
                              style={{ paddingBottom: "25px" }}
                            >
                              <h3 style={{ color: "black" }}>
                                <a
                                  href="#"
                                  style={{ color: "black" }}
                                  onClick={() => this.handlePreview(item)}
                                >
                                  {item.title}
                                </a>
                              </h3>
                              {/* <ul>
                                  <li>by <span>Ema Timahe</span></li>
                                  <li>Seb 17, 2019</li>
                              </ul> */}
                            </div>
                          </div>
                        </div>
                      ))
                    : null}

                  <div className="col-lg-12 col-md-12 mt-2">
                    <div className="text-center" style={{ marginTop: "50px" }}>
                      <Pagination
                        current={this.state.pageNumber}
                        onChange={this.onChangePageNumber}
                        total={this.state.totalSize}
                        showTotal={(total) => `Total ${total} posts`}
                        pageSize={this.state.pageSize}
                        showSizeChanger
                        onShowSizeChange={this.onShowSizeChange}
                        pageSizeOptions={[9, 20, 50, 100]}
                        // responsive={true}
                      />
                    </div>
                  </div>
                </div>
              </Spin>
            </div>
          </section>

          <Modal
            title={this.state.title}
            visible={this.state.isModalVisible}
            // onOk={false}
            onCancel={() => this.toggleModal("isModalVisible")}
            footer={null}
            width={1200}
            // style={{ top: 20 }}
          >
            <div className="row">
              <div className="col-lg-12">
                <div style={{ padding: "20px" }}>
                  <ReactPlayer
                    url={this.state.url}
                    playing={false}
                    loop={false}
                    width={"100%"}
                    height="500px"
                    // height={"100%"}
                    controls={true}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
